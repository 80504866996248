.page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 12px;
  box-sizing: border-box;
}

.content {
  flex: 1;
  overflow-y: auto;
  align-content: flex-start;
  margin: 12px 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 340px;
  overflow: hidden;
  height: 200px;
  padding: 18px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid rgba(221, 221, 221, 0.5);

  > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > span {
      flex: 1;
      margin-right: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .editOutlined {
      font-size: 18px;
      margin-top: 3px;
    }
  }
}

.modalContent {
  margin-top: 38px;

  .modalHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 58px;
  margin-bottom: 24px;
}

.unit {
  width: 100px;
}

.item {
  margin-bottom: 24px;
}

.formItem {
  margin-bottom: 0 !important;
}

.title {
  font-size: 24px;
  font-weight: 500;
}

.duration {
  margin-right: 2px;
}

.price {
  margin-left: 24px;
}

.detailListItem {
  margin-bottom: 10px;
}

.detailList {
  margin-top: 6px;
}

.empty {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
